import React, { useState, useEffect, useRef } from 'react'
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import useLocalStorage from '../hooks/useLocalStorage'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import QRCode from 'qrcode.react'
import { usePrevRoute } from './Store'
import { Modal, CloseButton } from './Modal'
import InvitationModal from './InvitationModal'
import ImportExport from './ImportExport'
import { useSession } from '../contexts/AuthContext'
import dayjs from 'dayjs'
import Button from './Button'
import qr from './Lib/qr'
import ModalPublicPage from './ModalPublicPage'
import debounce from 'lodash/debounce'
import Pagination from './Pagination'

const EVENT = 'Event'
const TICKET_TYPE = 'TicketType'
const ZONES = 'EventLocationZone'
const GUEST_QUOTA = 'GuestsQuota'

const modelsToHidden = ["GuestsCategory", "GuestsEntity", "GuestsInvitation", GUEST_QUOTA, "Organization"]

// NOTE: The cloning need to have an order, TICKET_TYPE needs always to be first
const parseDataToCloneOrder = (rawDataToClone = []) => {
  return rawDataToClone.sort((a, b) => {
    const order = [TICKET_TYPE, ZONES, GUEST_QUOTA]
    return order.indexOf(a) - order.indexOf(b)
  })
}

const List = ({ setUpdateEvents, setUpdateOrganizations, selectedMenuOrganization, selectedMenuEvent }) => {
  const history = useHistory()
  const { name: modelName, page: pageParam, event } = useParams()
  const { getOrganization, getEvent } = useOrganizationInfo(event)
  const [data, setData] = useState(false)
  const [userSortSettings, setUserSortSettings] = useLocalStorage('user_sort_settings', {})
  const [sortedValue, setSortedValue] = useState()
  const [sortAscending, setSortAscending] = useState(true)
  const [sortedItems, setSortedItems] = useState()
  const [title, setTitle] = useState(modelName)
  const [page, setPage] = useState(pageParam - 1 || false)
  const [hasMorePages, setHasMorePages] = useState(false)
  const [pageLimit, setPageLimit] = useState(50)
  const [duplicatedId, setDuplicatedId] = useState(false)
  const [showQR, setShowQR] = useState(null)
  const [loading, setLoading] = useState(false)
  const route = usePrevRoute()
  const [forbiddenModels, setForbiddenModels] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [cloningDataModal, setCloningDataModal] = useState({ show: false, id: null, cloningModels: [], loading: false })
  const [role, setRole] = useState(false)
  const [uploading, setUploading] = useState(false)
  const token = useSession()
  const [showModelModal, setShowModelModal] = useState(false)
  const [infoModelModal, setInfoModelModal] = useState({ title: "", text: "" })
  const [notificationText, setNotificationText] = useState('')
  const [showModalPublicPage, setShowModalPublicPage] = useState(false)
  const [publicPageId, setPublicPageId] = useState(false)
  const [status, setStatus] = useState()
  const [showDeleted, setShowDeleted] = useState(false)
  const debounced = useRef(debounce((fn, value) => fn(value), 300))
  const [searchInputValue, setSearchInputValue] = useState()
  const [selectedEventFromList, setSelectedEventFromList] = useState()

  const isEventModel = modelName === EVENT
  const isTicketTypeModel = modelName === TICKET_TYPE

  useEffect(() => {
    setShowModal(false)
    setStatus()
  }, [history.location.pathname])

  useEffect(() => {
    let prevRoute = false
    if (route) {
      prevRoute = route.split('/')
      prevRoute = prevRoute[3]
    }
    if (prevRoute && (prevRoute !== modelName)) {
      setPage(0)
    }
  }, [modelName])

  useEffect(() => {
    setData(false)

    const urlParts = history.location.pathname.split(modelName)

    if (urlParts[0].includes('no-access')) {
      return
    }

    if (page) {
      const updatePageUrl = `${urlParts[0]}${modelName}/${page + 1}`
      history.replace({ pathname: updatePageUrl })
    } else {
      const updatePageUrl = `${urlParts[0]}${modelName}`
      history.replace({ pathname: updatePageUrl })
    }
  }, [page, modelName, duplicatedId, uploading, history.location.pathname, showDeleted])

  const toggleStatus = (newStatus, currentStatus) => currentStatus === newStatus ? null : newStatus

  const getData = (pageSort) => {
    setLoading(true)
    if (modelsToHidden.includes(modelName)) {
      history.push(`/admin/no-access?url=${window.location.pathname}`)
      return
    }

    fetch(`${process.env.REACT_APP_API_URL}model-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        organization: getOrganization(),
        event: getEvent(),
        page,
        pageLimit,
        showDeleted,
        status,
        searchValue: searchInputValue,
        pageSort,
      }),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)

          if (isEventModel) {
            setTitle(`Organization events`)
          } else {
            setTitle(`List ${response?.body?.modelConfig?.title}`)
          }

          if (response?.body?.modelConfig?.pageLimit) {
            setPageLimit(response.body.modelConfig.pageLimit)
          }

          const rawBody = { ...response.body }
          setForbiddenModels(rawBody.forbiddenModels)
          rawBody?.modelConfig?.list && Object.keys(rawBody.modelConfig.list).map(key => {
            if (typeof rawBody.modelConfig.list[key] === 'object') {
              rawBody.modelConfig.list[key] = rawBody.modelConfig.list[key].label
            }
          })

          setData(rawBody)
          if (!pageSort) {
            setSortAscending(Object.values(rawBody.modelConfig.sort)[0] === 'asc' ? true : false)
            setSortedValue(Object.keys(rawBody.modelConfig.sort)[0])
          }

          if (response.body.pageLimit) {
            setHasMorePages(response.body.items.length === response.body.pageLimit)
          } else {
            setHasMorePages(response.body.items.length === pageLimit)
          }

        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  useEffect(() => {
    getData()
  }, [status])

  useEffect(() => {
    if (searchInputValue && searchInputValue.length > 2 || searchInputValue === '') {
      debounced.current(getData, data)
    }
  }, [searchInputValue])


  const duplicate = async (id) => {
    const createDuplicate = () => {
      return fetch(`${process.env.REACT_APP_API_URL}model-duplicate`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName,
          organization: getOrganization(),
          event: getEvent(),
          _id: id,
          relatedModelsToClone: cloningDataModal.cloningModels.length > 0 ? parseDataToCloneOrder(cloningDataModal.cloningModels) : []
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            setDuplicatedId(response.body.duplicatedId)
            setUpdateEvents(true)
            setUpdateOrganizations(true)
          } else if (response.status === 400) {
            alert('Error ' + response.body.error)
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('user')
              history.push('/login')
            } else if (response.status === 401) {
              history.push(`/admin/no-access?url=${window.location.pathname}`)
            }
          }
        })
    }
    if (isEventModel) {
      setCloningDataModal(state => ({ ...state, loading: true }))
      await createDuplicate().finally(() => {
        setCloningDataModal(state => ({ ...state, loading: false }))
        handleShowCloningDataModal()
      })
    }
    else if (window.confirm('Clone Model?')) {
      await createDuplicate()
    }
    // Hack to see the cloned item
    window.location.reload()
  }

  const remove = id => {
    if (window.confirm('Delete?')) {
      fetch(`${process.env.REACT_APP_API_URL}model-delete`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName,
          organization: getOrganization(),
          event: getEvent(),
          _id: id,
          firebaseUID: data.items.find(item => item._id === id).firebaseUID,
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            if (isEventModel) {
              setUpdateEvents(id)
            }
            if (modelName === 'Organization') {
              setUpdateOrganizations(id)
            }
            data.items = data.items.filter(item => item._id !== id)
            setData({ ...data })
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('user')
              history.push('/login')
            } else if (response.status === 401) {
              history.push(`/admin/no-access?url=${window.location.pathname}`)
            }
          }
        })
    }
  }

  const handleStatus = ({ id, status }) => {
    //NOTE: currently handling null as active
    if (window.confirm(`Do you want change the status to ${status || 'active'}?`)) {
      fetch(`${process.env.REACT_APP_API_URL}model-update-status`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName,
          organization: getOrganization(),
          event: getEvent(),
          _id: id,
          status,
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          getData()
        }).catch(error => {
          console.error(error)
        })
    }
  }

  useEffect(() => {
    const settings = { ...userSortSettings }
    if (settings.hasOwnProperty(modelName)) {
      const { value, ascending } = settings[modelName]
      setSortedValue(value)
      setSortAscending(ascending)
      getData({ [value]: ascending ? 1 : -1 })
    } else {
      getData()
    }
    setUserSortSettings(settings)
  }, [modelName, page])

  useEffect(() => {
    const settings = { ...userSortSettings }
    if (typeof sortedValue === 'undefined' || typeof sortAscending === 'undefined') {
      delete settings[modelName]
    } else {
      settings[modelName] = {
        value: sortedValue,
        ascending: sortAscending,
      }
    }
    setUserSortSettings(settings)
  }, [sortedValue, sortAscending])

  const handleSort = key => {
    let rawSortedValue = key
    let rawSortAscending = true
    if (sortedValue === rawSortedValue) {
      if (sortAscending) {
        rawSortAscending = false
      } else {
        rawSortedValue = undefined
        rawSortAscending = undefined
      }
    } else {
      rawSortedValue = key
      rawSortAscending = true
    }
    setSortAscending(rawSortAscending)
    setSortedValue(rawSortedValue)
    getData({
      [rawSortedValue]: rawSortAscending ? 1 : -1,
    })
  }

  useEffect(() => {
    if (typeof data === 'object' && typeof data.items !== 'undefined') {
      const items = [...data.items]
      if (typeof sortedValue !== 'undefined') {
        const sorted = items.sort((a, b) => {
          const itemA = typeof a[sortedValue] == 'string' ? a[sortedValue].toLowerCase() : undefined
          const itemB = typeof b[sortedValue] == 'string' ? b[sortedValue].toLowerCase() : undefined

          if (typeof itemA === 'undefined' && typeof itemB === 'undefined') return 0
          if (typeof itemA === 'undefined') return sortAscending ? 1 : -1
          if (typeof itemB === 'undefined') return sortAscending ? -1 : 1

          if (itemA < itemB) return sortAscending ? -1 : 1
          if (itemA > itemB) return sortAscending ? 1 : -1
          return 0
        })
        setSortedItems(sorted)
      } else {
        setSortedItems(data.items)
      }
    }
  }, [data, sortedValue, sortAscending, modelName])

  const handleShowModal = () => setShowModal(state => !state)
  const handleShowCloningDataModal = (id = null) => {
    setCloningDataModal(state => {
      if (state.show) return { show: false, id: null, cloningModels: [] }
      return { ...state, show: true, id }
    })  
  }

  const handleDataToClone = (rawDataToClone) => {
    setCloningDataModal(state => ({ ...state, cloningModels: rawDataToClone }))
  }

  const MODELS_ALLOWED_TO_ARCHIVE = [EVENT, TICKET_TYPE]
  const isArchivable = MODELS_ALLOWED_TO_ARCHIVE.includes(modelName)

  const handleClone = (id) => {
    if (isEventModel) handleShowCloningDataModal(id)
    else duplicate(id)
  }


  const cloningData = [
    {field: TICKET_TYPE, label: 'Ticket Type'},
    {field: ZONES, label: 'Zones'},
    {field: GUEST_QUOTA, label: 'Guest'},
  ]

  const ExportField = ({ field, label }) => (
    <span className="flex mr-4">
      <input
        type="checkbox"
        id={field}
        className="cursor-pointer cloneData"
        onChange={(e) => {
          document.getElementById('allFields').checked = false;
          let rawDataToClone = [...cloningDataModal.cloningModels];
          if (e.target.checked) {
            rawDataToClone.push(e.target.id);
          } else {
            const index = rawDataToClone.indexOf(e.target.id);
            if (index > -1) {
              rawDataToClone.splice(index, 1);
            }
            // NOTE: TicketType is required for cloning Zones and GuestsQuota
            // If TicketType is deselected, also remove Zones and GuestsQuota
            if (e.target.id === TICKET_TYPE) {
              rawDataToClone = rawDataToClone.filter(item => item !== ZONES && item !== GUEST_QUOTA);
            }
          }
          handleDataToClone(rawDataToClone);
        }}
        checked={cloningDataModal.cloningModels.includes(field)}
        disabled={field !== TICKET_TYPE && !cloningDataModal.cloningModels.includes(TICKET_TYPE)}
      />
      <label className="ml-1 text-sm cursor-pointer" htmlFor={field}>
        {label || field}
      </label>
    </span>
  );
  const Actions = ({ id, modelName, code, publicId, itemPublicPageId, deleted, status: currentStatus }) => {
    return (
      <div className="relative flex items-center text-xs">
        {publicId && typeof qr[modelName] !== 'undefined' && <span onClick={() => setShowQR(modelName === 'Giftcard' ? code : publicId)}>
          <img src="/assets/images/qr.svg" alt="" className="w-4 h-4 mr-4 cursor-pointer" />
        </span>}
        <Link className={`${deleted ? 'mr-2' : 'mr-4'} cursor-pointer hover:text-primary hover:wght-semibold`} to={{ pathname: `/admin/${event}/edit/${modelName}/${id}`, state: { page } }}>View</Link>
        {role !== 'read' && !deleted && <span className="block mr-4 cursor-pointer hover:text-primary hover:wght-semibold" onClick={() => handleClone(id)}>Clone</span>}
        {role !== 'read' && !deleted && isArchivable && <span className="block mr-4 cursor-pointer hover:text-primary hover:wght-semibold" onClick={() => handleStatus({ id, status: toggleStatus('archived', currentStatus) })}>{ currentStatus ? 'Activate' : 'Archive'}</span>}
        {role !== 'read' && !deleted && !forbiddenModels.includes(modelName) &&
          <span className="block cursor-pointer hover:text-primary hover:wght-semibold" onClick={() => remove(id)}>Delete</span>
        }
        <Button disable={loading} visibility={role === 'admin' && !!data.modelConfig.publicUrlEnabled } size="small" onClick={() => {
          setShowModalPublicPage(id)
          if (itemPublicPageId) {
            setPublicPageId(itemPublicPageId)
          }
        }} color={itemPublicPageId ? 'success' : 'white'} className=' whitespace-nowrap'>Public</Button>
        <Button visibility={role === 'admin' && modelName === 'Event'} size="small" onClick={() => setSelectedEventFromList(id)} color='success'>Share</Button>
      </div>
    )
  }


  const handleChange = (e) => {
    setUploading(true)
    const currentFiles = e.target.files

    e.preventDefault()
    const formData = new FormData()

    let index = 0

    for (const key in currentFiles) {
      if (Object.hasOwnProperty.call(currentFiles, key)) {
        const file = currentFiles[key];
        formData.append(`file${index}`, file)
        index++
      }
    }

    formData.append(`organization`, getOrganization())
    formData.append(`event`, getEvent())
    formData.append(`modelName`, modelName)

    fetch(`${process.env.REACT_APP_API_URL}import-artist-set`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setUploading(false)
        if (response.status === 200) {
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const parseField = (key, item) => {

    if (key === 'firebaseUID') {
      return <span className={`mr-4 text-sm whitespace-nowrap ${item[key] ? 'text-primary' : 'text-error'}`}>
        {item[key] ? 'Registered' : 'Not registered'}
      </span>
    }

    if (!item[key] || typeof item[key] === 'undefined') {
      return ''
    }

    if (key === 'userId') {
      return <Link
        className="mr-4 cursor-pointer hover:text-primary hover:wght-semibold text-primary"
        to={`/admin/user/${item[key].id}/${item[key].profile._id}`}
      >
        {item[key].profile.name}
      </Link>
    }

    if (key.includes("modal")) {
      return <button
        className="flex mt-1 text-xs cursor-pointer pointer-events-auto text-primary hover:wght-semibold"
        onClick={() => {
          console.log('heeeey')
          setShowModelModal(state => !state)
          setInfoModelModal({
            title: data.modelConfig.list[key],
            text: item[key]
          })
        }}
      >
        <span className='mr-1 text-lg -mt-2 pt-0.5'>≣</span> {data.modelConfig.list[key]}
      </button>
    }

    if (Array.isArray(item[key])) {
      return item[key].map((i, index) => {
        const subitem = {}
        subitem[key] = i
        return parseField(key, subitem)
      }).join(', ')
    }

    if (typeof item[key] === 'object') {
      return item[key].label || item[key].title || item[key].en || JSON.stringify(item[key])
    }

    if (['date', 'day'].some(v => key.toLowerCase().includes(v)) && dayjs(item[key]).isValid()) {
      return dayjs(item[key]).format(`DD/MM/YY${isTicketTypeModel ? ' HH:mm' :  ''}`)
    }

    if (['datetime', 'time', 'createdat', 'updatedat'].some(v => key.toLowerCase().includes(v)) && dayjs(item[key]).isValid()) {
      return dayjs(item[key]).format('DD/MM/YYYY HH:mm:ss')
    }

    return item[key]
  }

  const handleCopyText = (field, text) => {
    navigator.clipboard.writeText(text)
    setNotificationText(`Copied ${field}: ${text}`)
  }

  useEffect(() => {
    if (notificationText) {
      setTimeout(() => {
        setNotificationText('')
      }, 3000);
    }
  }, [notificationText])

  useEffect(() => {
    if (!showModalPublicPage) {
      setPublicPageId(false)
      if (!loading) {
        getData()
      }
    }
  }, [showModalPublicPage])

  useEffect(() => {
    if (selectedEventFromList) {
      setShowModal(true)
    }
  }, [selectedEventFromList])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex'>
        <div className="relative flex grow-1">
          <h2 className="text-xl wght-semibold">{title}</h2>
          {showModal &&
            <InvitationModal token={token} handleShowModal={handleShowModal} modelName={modelName} event={selectedEventFromList || event} role={role}/>
          }
        </div>

        <div className="relative flex justify-end grow-0">
          <div className="relative flex items-center justify-center">
            <Button size="small" onClick={() => handleShowModal()} color='success' visibility={role === 'admin' && modelName !== 'Event' || false}
            >Share</Button>
            <Button visibility={role !== 'read'} size="small" to={`/admin/${event}/edit/${modelName}`}>
              Add
            </Button>
          </div>
        </div>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      {notificationText &&
        <span className='fixed z-10 px-2 py-1 text-sm text-white border rounded bottom-5 right-5 bg-primary'>{notificationText}</span>
      }

      <ModalPublicPage
        data={data}
        event={event}
        modelName={modelName}
        showModalPublicPage={showModalPublicPage} 
        setShowModalPublicPage={setShowModalPublicPage}
        publicPageId={publicPageId}
        setPublicPageId={setPublicPageId}
      />

      {showQR && typeof qr[modelName] !== 'undefined' &&
        <div className="fixed inset-0 z-40 flex items-center justify-center" style={{ backgroundColor: 'rgba(255,255,255,.5)' }} onClick={() => setShowQR(null)}>
          <div className="flex flex-col justify-center p-4 bg-white cursor-pointer" onClick={() => handleCopyText('QR code', `acst://${qr[modelName]}/${showQR}`)}>
            <QRCode size={300} value={`acst://${qr[modelName]}/${showQR}`} />
            <span className="p-2 mt-2 text-xs bg-white">{`acst://${qr[modelName]}/${showQR}`}</span>
          </div>
        </div>
      }

      <div className="relative flex items-center justify-start mt-2">
        {event !== 'all' && role !== 'read' && modelName === 'ArtistSet' && !loading && (
          <ImportExport uploading={uploading} handleImport={handleChange} description="Required fields: artist.en, venueName.en, day. Translatable fields format: field.language" />
        )}

        {event === 'all' && role !== 'read' && modelName === 'ArtistSet' && !loading ? (
          <div className="mt-2 text-sm">Select event to import</div>
        ) : (
          <div className="mb-8"></div>
        )}
        { loading && <div className=''>Loading...</div>}
      </div>

      {data.modelConfig?.search && <div className="flex items-center ">
        <input
          name="searchInputValue"
          id="searchInputValue"
          className="px-2 py-1 bg-white border rounded outline-none"
          type="text"
          placeholder={`Search: ${data.modelConfig?.search.join(', ')}`}
          onChange={(e) => setSearchInputValue(e.target.value)}
          value={searchInputValue}
          
        />
      </div>}

      {data && data.items.length === 0 && <div className='mt-8'>Nothing to list</div>}
      {data && data.items.length > 0 && sortedItems &&
        <div className="mt-2 overflow-x-auto">

          <div className='flex items-center justify-between mb-2'>
            <div className="relative flex text-sm text-gray wght-light">
              {modelName === 'PttUser' &&
                <div>
                  <span className=''>{selectedMenuEvent ?
                    <span>These users have access to <strong className='wght-semibold'>{selectedMenuEvent?.label}</strong> event.</span>
                    : <span>These users have access to <strong className='wght-semibold'>{selectedMenuOrganization?.label}</strong> organization or one of their events.</span>}
                  </span>
                </div>
              }
            </div>
            { role !== 'read' && !forbiddenModels.includes(modelName) &&
              <Button size="small" color={showDeleted ? 'invertedGreen' : 'white'} onClick={() => setShowDeleted(!showDeleted)} >
                Show deleted {modelName}
              </Button> }
            { role !== 'read' && isArchivable && 
              <Button size="small" color={status == 'archived' ? 'invertedGreen' : 'white'} onClick={() => setStatus(toggleStatus('archived', status))} >
                Show {status ? 'active' : 'archived'}
              </Button> }
          </div>
          {/* {modelName === 'PttUser' && (
          )} */}

          <table className="w-full">
            <thead className="w-full text-left bg-white border-b border-grayLight">
              <tr>
                {Object.keys(data.modelConfig.list).map((key, index) => {
                  if (key === '_id') return
                  if (typeof data.modelConfig.list[key] === 'undefined') return
                  return (
                    <th key={key} className={`px-4 py-2${index === 1 ? ' sticky left-0 bg-white z-1' : ''}`}>
                      <span
                        className="relative cursor-pointer weight-semibold"
                        onClick={() => !loading && handleSort(key)}
                      >
                        {typeof data.modelConfig.list[key] === 'string' && data.modelConfig.list[key]}
                        {typeof data.modelConfig.list[key] === 'object' && data.modelConfig.list[key].label}
                        {sortedValue === key && (
                          <span className="absolute inset-y-0 right-0 flex items-center justify-center -mr-4 text-center">
                            {sortAscending ? "↑" : "↓"}
                          </span>
                        )}
                      </span>
                    </th>
                  )
                })}
                {modelName !== 'PttInvitation' && (
                  <th className="px-4 py-2 text-right wght-semibold">Actions</th>
                )}
              </tr>
            </thead>
            <tbody>
              {sortedItems.map(item => <tr key={item._id} className={`relative ${item.deleted ? 'text-error  hover:text-errorLight' : ''} bg-white hover:bg-grayLighter border-b border-grayLight  ${modelName === 'ticket' ? 'text-sm' : ''}`}>
                {Object.keys(data.modelConfig.list).map((key, index) => {
                  if (key === '_id') return
                  if (typeof data.modelConfig.list[key] === 'undefined') return
                  return (
                    <td
                      key={key}
                      className={`px-4 py-2 whitespace-nowrap text-sm truncate${index === 1 ? ' sticky left-0 bg-white' : ''}`}
                      style={{ maxWidth: '350px' }} // Adjust '150px' to control the width of the cell
                    >
                      {parseField(key, item)}
                    </td>
                  )
                })}
                {modelName !== 'PttInvitation' && (
                  <td className="table-cell px-4 py-2 text-right">
                    <div className="flex justify-end">
                      <Actions modelName={modelName} id={item._id} code={item.code} deleted={item.deleted} publicId={modelName === "EventLocationZone" ? item._id : item.publicId} itemPublicPageId={item.publicPageId} status={item.status} />
                    </div>
                  </td>
                )}
              </tr>)}
            </tbody>
          </table>
        </div>
      }

        <Modal setShowModal={handleShowCloningDataModal} showModal={cloningDataModal.show}>
          <div className="relative w-1/3 pb-0 overflow-auto bg-white z-1 max-h-128">
            <div className="flex items-center justify-between w-full p-8 pb-2 h-fit">
              <h2 className="text-xl wght-semibold">Clone Event</h2>
            </div>
            <div className="w-full p-8 pt-2 pb-0">
              <h3 className="mb-1 wght-semibold">Additional data to clone</h3>
            </div>
            <div className="p-8 pt-0 pb-0 mb-1 select-none">
              <input
                type="checkbox"
                id="allFields"
                className="cursor-pointer"
                onChange={(e) => {
                  const checkboxes = document.querySelectorAll('.cloneData')
                  const rawDataToClone = []
                  checkboxes.forEach((checkbox) => {
                    checkbox.checked = e.target.checked
                    rawDataToClone.push(checkbox.id)
                  })
                  handleDataToClone(e.target.checked ? rawDataToClone : []);
                }}
              />
              <label
                className="ml-1 text-sm cursor-pointer wght-semibold"
                for="allFields"
              >
                All
              </label>
            </div>
            <div className="flex flex-wrap w-full p-8 pt-0 select-none">
              <span>
                <span className="text-sm wght-semibold">Ticket Data:</span>
                {cloningData.map(({field, label}, index) => (
                  <ExportField key={field} field={field} label={label} />
                ))}
              </span>
            </div>
            <div className="sticky bottom-0 left-0 flex items-end justify-end p-8 text-xs bg-white border-grayLight">
              {loading ? (
                <div>
                  <span className="px-2 py-1 text-xs border rounded cursor-pointer text-primary bg-primaryLighter border-primary">
                    Cloning...
                  </span>
                </div>
              ) : (
                <div className='flex'>
                  <Button className='mr-2 text-base' onClick={handleShowCloningDataModal} color="error">Cancel</Button>
                  <Button className='mr-2 text-base' disable={cloningDataModal.loading} onClick={() => duplicate(cloningDataModal.id)}>Clone</Button>
                </div>
              )}
              </div>
          </div>
        </Modal>

      <Modal setShowModal={setShowModelModal} showModal={showModelModal}>
        <div className="relative w-auto pb-2 overflow-auto bg-white z-1 max-h-128">
          <CloseButton setShowModal={setShowModelModal} />
          <div className="w-full p-8 pb-2 h-fit">
            <h2 className="text-xl wght-semibold">{infoModelModal.title}</h2>
          </div>
          <div className="w-full p-8 pt-2">
            <textarea
              className="p-4"
              disabled
              readOnly
              rows="10" cols="25"
              value={
                typeof infoModelModal.text === 'string' ? infoModelModal.text : JSON.stringify(infoModelModal.text, null, 2)
              }
            />
          </div>
        </div>
      </Modal>



      <Pagination
        page={page}
        setPage={setPage}
        hasMorePages={hasMorePages}
        loading={!data?.items?.length && loading}
        disabled={data?.items?.length && loading}
      />
    </>
  )
}

export default List
